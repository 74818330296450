import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

function PageContent({ data }) {
  return (
    <div className="content icons">
      <a
        href="https://twitter.com/kagibbs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img fluid={data.twitter.childImageSharp.fluid} />
      </a>
      <a
        href="https://www.linkedin.com/in/kashifgibbs/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img fluid={data.linkedin.childImageSharp.fluid} />
      </a>
      <a
        href="https://www.facebook.com/kashif.gibbs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img fluid={data.facebook.childImageSharp.fluid} />
      </a>
      {/* <Img fluid={data.pay.childImageSharp.fluid} /> */}
    </div>
  )
}

const Form = () => (
  <form method="post" action="/thanks" netlify-honeypot="bot-field" data-netlify="true" name="contact">
    <input type="hidden" name="bot-field" />
    <input type="hidden" name="form-name" value="contact" />
    <div className="info">
      <label htmlFor="name">Name</label>
      <input type="text" name="name" id="name" required/>

      <label htmlFor="email">Email</label>
      <input type="email" name="email" id="email" required/>

      <label htmlFor="subject">Subject</label>
      <input type="text" name="subject" id="subject" required/>
    </div>
    <div className="message">
      <label htmlFor="message">Message</label>
      <textarea name="message" id="message" />
    </div>

    <button type="submit">Send</button>
  </form>
)

const socialIcons = () => (
  <StaticQuery
    query={graphql`
      {
        facebook: file(relativePath: { eq: "assets/images/connect_fb.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        linkedin: file(
          relativePath: { eq: "assets/images/connect_linkedin.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        twitter: file(
          relativePath: { eq: "assets/images/connect_twitter.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pay: file(relativePath: { eq: "assets/images/connect_pay.png" }) {
          childImageSharp {
            fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <div className="connectContent">
        <PageContent data={data} />
        <Form />
      </div>
    )}
  />
)

export default socialIcons;
